<template>
    <el-dialog title="选择图书" class="dialogTable" :close-on-click-modal="false" :fullscreen="true" append-to-body :visible.sync="visible" v-if="visible">
        <el-form size="small" :inline="true" class="searchForm" ref="searchForm" :model="searchForm"
                 label-width="100px" @keyup.enter.native="search(1)">
            <el-form-item prop="bookName" label="输入搜索：">
                <el-input v-model.trim="searchForm.bookName" maxlength="100" placeholder="请输入书名（限100字）"
                          clearable @keyup.enter.native="search(1)"></el-input>
            </el-form-item>
            <el-form-item prop="bookType" label="图书分类：">
                <el-select v-model="searchForm.bookType" placeholder="请选择图书分类" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('book_type')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="publicationTime" label="出版时间：">
                <el-date-picker
                        value-format="yyyy"
                        format="yyyy"
                        v-model="searchForm.publicationTime"
                        type="year" :picker-options="pickerOptions"
                        placeholder="请选择出版时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label-width="20px">
                <el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
                <el-button icon="el-icon-refresh-right" @click="reset()">重置</el-button>
            </el-form-item>
            <el-button class="f_r" size="small" type="primary" @click="add()">确定</el-button>
        </el-form>
        <el-table
                :data="dataList"
                v-loading="loading"
                size="small"
                height="calc(100vh - 226px)"
                @selection-change="selectionChangeHandle"
                class="table" ref="multipleTable" row-key='id'>
            <el-table-column type="selection" :reserve-selection='true' :selectable="(row) => {return !selectedList.find((v) => v.id == row.id);}" fixed/>
            <el-table-column prop="areaCode" label="区域编号" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bookName" label="书名" show-overflow-tooltip></el-table-column>
            <el-table-column prop="bookType" label="图书分类" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{$dictUtils.getDictLabel("book_type", scope.row.bookType ,'')}}
                </template>
            </el-table-column>
            <el-table-column prop="publishingHouse" label="出版社" show-overflow-tooltip></el-table-column>
            <el-table-column prop="publicationTime" label="出版时间" width="150" sortable show-overflow-tooltip></el-table-column>
            <el-table-column prop="bookNumber" label="份数" show-overflow-tooltip></el-table-column>
            <el-table-column prop="pricing" label="定价（￥）" show-overflow-tooltip></el-table-column>
            <el-table-column prop="state" label="状态" show-overflow-tooltip>
                <template slot-scope="scope">
                    {{$dictUtils.getDictLabel("book_state", scope.row.state ,'')}}
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" show-overflow-tooltip></el-table-column>
            <el-table-column fixed="right" width="200" label="操作">
                <template slot-scope="scope">
                    <el-button type="text" size="small" @click="addEdit(scope.row, 'view')">详情
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="sizeChangeHandle"
                @current-change="currentChangeHandle"
                :current-page="searchForm.current"
                :page-sizes="[10, 20, 50, 100]"
                :page-size="searchForm.size"
                :total="total"
                background
                layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
        <ManageForm ref="manageForm"></ManageForm>
    </el-dialog>
</template>


<script>
    import ManageForm from './manageForm'

    export default {
        components: {ManageForm},
        data() {
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getFullYear() < 1940 || time.getFullYear() > new Date().getFullYear()
                    }
                },
                visible: false,
                loading: false,
                searchForm: {
                    bookName: '',
                    publicationTime: '',
                    bookType: '',
                    state: '0',
                    current: 1,
                    size: 10,
                },
                total: 0,
                dataList: [],
                dataListSelections: [],
                selectedList: [],
                selectedTotal: 0,
            }
        },
        methods: {
            // list 已选数据
            // total 选择条数
            init(list, total) {
                this.selectedList = list
                this.selectedTotal = total
                this.dataListSelections = []
                this.visible = true
                this.$nextTick(() => {
                    this.reset()
                })
            },
            // 查询
            search(type) {
                if (type == 1) {
                    this.searchForm.current = 1
                }
                this.loading = true
                let p = JSON.parse(JSON.stringify(this.searchForm))
                this.$axios(this.api.record.archivesbookList, p, 'post').then((res) => {
                    if (res.status) {
                        this.dataList = res.data.records
                        if (this.dataList.length == 0 && this.searchForm.current > 1) {
                            this.searchForm.current--
                            this.search()
                        }
                        this.total = parseInt(res.data.total)
                    } else {
                        this.$message.error('查询失败');
                    }
                    this.loading = false
                })
            },
            // 确定
            add() {
                if(!this.dataListSelections.length) {
                    this.$message.warning('请至少选择一条数据')
                    return
                }
                if(this.selectedTotal && this.dataListSelections.length + this.selectedList.length > this.selectedTotal) {
                    this.$message.error('最多选择'+this.selectedTotal+'本图书')
                    return
                }
                this.$emit('getDataList', this.dataListSelections)
                this.visible = false
            },
            // 详情
            addEdit(row, method) {
                this.$refs.manageForm.init(row, method)
            },
            // 重置
            reset() {
                this.$refs.searchForm.resetFields();
                this.search(1)
            },
            // 多选
            selectionChangeHandle(val) {
                this.dataListSelections = val
            },
            // 每页数
            sizeChangeHandle(val) {
                this.searchForm.size = val
                this.searchForm.current = 1
                this.search()
            },
            // 当前页
            currentChangeHandle(val) {
                this.searchForm.current = val
                this.search()
            },
        }
    }
</script>

<style scoped>

</style>
