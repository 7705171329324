<template>
    <el-dialog :title="title" width="70%" :close-on-click-modal="false" append-to-body :visible.sync="visible">
        <el-form class="recordCollect" size="small" :inline="true" :model="inputForm" :rules="dataRule" ref="inputForm"
                 label-width="180px" :disabled="method==='view'" v-loading="loading">
            <el-form-item label="书名：" prop="bookName" class="rowBox">
                <el-input v-model.trim="inputForm.bookName" maxlength="100" placeholder="请输入书名（限100字）"></el-input>
            </el-form-item>
            <el-form-item label="图书分类：" prop="bookType">
                <el-select v-model="inputForm.bookType" placeholder="请选择图书分类" clearable class="w100i">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in $dictUtils.getDictList('book_type')">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="区域编号：" prop="areaCode">
                <el-input v-model.trim="inputForm.areaCode" maxlength="10" placeholder="请输入区域编号（限10字）"></el-input>
            </el-form-item>
            <el-form-item label="出版社：" prop="publishingHouse">
                <el-input v-model.trim="inputForm.publishingHouse" maxlength="50" placeholder="请输入出版社（限50字）"></el-input>
            </el-form-item>
            <el-form-item label="出版时间：" prop="publicationTime">
                <el-date-picker class="w100i"
                                value-format="yyyy"
                                format="yyyy"
                                v-model="inputForm.publicationTime"
                                type="year" :picker-options="pickerOptions"
                                placeholder="请选择出版时间">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="份数：" prop="bookNumber">
                <el-input v-model.trim="inputForm.bookNumber" maxlength="2" placeholder="请输入份数（1-99）" @input="inputForm.bookNumber = inputForm.bookNumber.replace(/[^\d]/g, '')"></el-input>
            </el-form-item>
            <el-form-item label="定价(￥)：" prop="pricing">
                <el-input v-model.trim="inputForm.pricing" maxlength="4" placeholder="请输入定价（0-9999）" @input="inputForm.pricing = inputForm.pricing.replace(/[^\d.]/g, '')"></el-input>
            </el-form-item>
            <el-form-item label="状态：" prop="state">
                <el-select v-model="inputForm.state" placeholder="请选择状态" clearable class="w100i" :disabled="inputForm.state == 1">
                    <el-option
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            v-for="item in stateOpt">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="备注：" prop="remark" class="rowBox">
                <el-input
                        maxlength="500"
                        type="textarea"
                        :rows="2"
                        placeholder="请输入备注信息。（限500字）"
                        v-model="inputForm.remark">
                </el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="text_center">
            <el-button size="small" @click="visible = false">{{method == 'view' ? '关闭' : '取消'}}</el-button>
            <el-button size="small" type="primary" @click="doSubmit()" v-if="method != 'view'" v-noMoreClick>保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>

    export default {
        data() {
            // 校验份数
            var validateBookNumber = (rule, value, callback) => {
                if (value && !/^(([1-9][0-9])|[1-9])$/.test(value)) {
                    callback(new Error('请输入正确的份数（1-99）'));
                    return
                }
                callback();
            };
            return {
                pickerOptions: {
                    disabledDate(time) {
                        return time.getFullYear() < 1940 || time.getFullYear() > new Date().getFullYear()
                    }
                },
                title: '',
                visible: false,
                method: '',
                loading: false,
                inputForm: {
                    id: '',
                    bookName: '',
                    bookType: '',
                    areaCode: '',
                    publishingHouse: '',
                    publicationTime: '',
                    bookNumber: '',
                    pricing: '',
                    state: '0',
                    remark: '',
                },
                stateOpt: this.$dictUtils.getDictList('book_state').filter(v => v.value != 1),
                dataRule: {
                    bookName: [
                        {required: true, message: '书名不能为空', trigger: 'blur'}
                    ],
                    bookType: [
                        {required: true, message: '图书分类不能为空', trigger: 'change'}
                    ],
                    areaCode: [
                        {required: true, message: '区域编号不能为空', trigger: 'blur'}
                    ],
                    bookNumber: [
                        {validator: validateBookNumber, trigger: 'blur'}
                    ],
                    pricing: [
                        {validator: this.validator.isPrice, trigger: 'blur'}
                    ],
                }
            }
        },
        methods: {
            init(row, method) {
                this.loading = false
                if (row) {
                    this.inputForm.id = row.id
                } else {
                    this.inputForm.id = ''
                }
                this.method = method
                if (method === 'add') {
                    this.title = '新增图书'
                } else if (method === 'edit') {
                    this.title = '修改图书'
                } else if (method === 'view') {
                    this.title = '查看图书'
                }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method !== 'add') {
                        this.getInfo()
                    }
                })
            },
            getInfo() {
                this.loading = true
                this.$axios(this.api.record.archivesbookGetById + '/' + this.inputForm.id).then(data => {
                    if (data.status) {
                        this.recover(this.inputForm, data.data);
                        if(this.inputForm.state == 1) {
                            this.stateOpt = this.$dictUtils.getDictList('book_state')
                        } else {
                            this.stateOpt = this.$dictUtils.getDictList('book_state').filter(v => v.value != 1)
                        }
                        this.loading = false
                    }
                })
            },
            // 提交
            doSubmit() {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        let api = this.api.record.archivesbookSave, type = 'post'
                        if (this.method === 'edit') {  // 修改
                            api = this.api.record.archivesbookUpdateById
                            type = 'put'
                        }
                        this.$axios(api, this.inputForm, type).then(data => {
                            if (data.status) {
                                this.$message.success('保存成功')
                                this.visible = false
                                this.$emit('refreshDataList')
                            } else {
                                this.$message.error('保存失败')
                            }
                            this.loading = false
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .rowBox {
        width: 97% !important;
    }
</style>
